import React from 'react';

const Experience = () => {
    return (
        <div className="experience">
            <h3>Expérience</h3>
            <div className="exp-1">
                <h4>Responsable de secteur - Gifi</h4>
                <h5>2018 - 2023</h5>
                <p>
                    -Gestion de 21 magasins en île de France
                    <br /> -Organisation des budgets <br /> -Gestion des comptes d'exploitation <br /> -Réalisation de 70 millions d'euros de CA avec
                    augmentation de 9 % sur les années précédentes
                </p>
            </div>
            <div className="exp-2">
                <h4>Manager en restauration rapide - Quick</h4>
                <h5>2015 - 2018</h5>
                <p>
                    -Encadrement d'une équipe de 50 personnes <br />
                    -Gestion des stocks <br />
                    -Établissement du planning et des horaires <br />
                    -Réalisation de 4 millions d'euros de CA avec évolution de 5 %
                </p>
            </div>
        </div>
    );
};

export default Experience;
