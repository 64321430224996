import React from 'react';

const OtherSkills = () => {
    return (
        <div className="otherSkills">
            <h3>Autres compétences</h3>
            <div className="list">
                <ul>
                    <li>
                        <i className="fas fa-check-square"></i>Anglais
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>Github
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>Optimisation SEO
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>Gestion de projet
                    </li>
                </ul>
                <ul>
                    <li>
                        <i className="fas fa-check-square"></i>Figma
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>UI/UX Design
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>Mobile First
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>Tests unitaires
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default OtherSkills;
