import React from 'react';
import Navigation from '../components/Navigation';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Contact = () => {
    return (
        <div className="contact">
            <Navigation />
            <div className="contactContent">
                <div className="header"></div>
                <div className="contactBox">
                    <h1>Contactez-moi</h1>
                    <ul>
                        <li>
                            <i className="fas fa-map-marker-alt"></i>
                            <span>Auxerre</span>
                        </li>
                        <li>
                            <i className="fas fa-mobile-alt"></i>
                            <CopyToClipboard text="0761537716">
                                <span
                                    className="clickInput"
                                    onClick={() => {
                                        alert('Téléphone copié !');
                                    }}
                                >
                                    07 61 53 77 16
                                </span>
                            </CopyToClipboard>
                        </li>
                        <li>
                            <i className="far fa-envelope"></i>
                            <CopyToClipboard text="schmit.geoffrey4@gmail.com">
                                <span
                                    className="clickInput"
                                    onClick={() => {
                                        alert('E-mail copié !');
                                    }}
                                >
                                    schmit.geoffrey4@gmail.com
                                </span>
                            </CopyToClipboard>
                        </li>
                    </ul>
                </div>
                <div className="socialNetwork">
                    <ul>
                        <a href="https://www.linkedin.com/in/geoffrey-schmit/" target="_blank" rel="noopener noreferrer">
                            <h4>LinkedIn</h4>
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="https://github.com/Geoffrey-Schmit" target="_blank" rel="noopener noreferrer">
                            <h4>Github</h4>
                            <i className="fab fa-github"></i>
                        </a>
                        <a href="https://www.facebook.com/geoffrey.schmit?locale=fr_FR" target="_blank" rel="noopener noreferrer">
                            <h4>Facebook</h4>
                            <i className="fab fa-facebook"></i>
                        </a>
                        <a href="https://twitter.com/SchmitGeo89" target="_blank" rel="noopener noreferrer">
                            <h4>Twitter</h4>
                            <i className="fab fa-x-twitter"></i>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Contact;
