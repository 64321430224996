export const portfolioData = [
    {
        id: 1,
        name: 'Booki',
        languages: ['html', 'css', 'tous'],
        languagesIcons: ['fa-brands fa-html5', 'fab fa-css3-alt'],
        source: 'https://github.com/Geoffrey-Schmit/booki',
        info: "L’entreprise souhaitait développer un site Internet qui permette aux usagers de trouver des hébergements et des activités dans la ville de leur choix. Réalisation de l'interface du site avec du code HTML et CSS ",
        picture: './media/project1.jpg',
    },
    {
        id: 2,
        name: 'Oh My Food',
        languages: ['html', 'sass', 'tous'],
        languagesIcons: ['fa-brands fa-html5', 'fab fa-sass'],
        source: 'https://github.com/Geoffrey-Schmit/Ohmyfood',
        info: "Développement du site Oh My Food en mobile first. Nous souhaitons proposer à nos clients les menus de restaurants gastronomiques. Après l'avoir développé à New - York dans un premier temps, nous souhaitons désormais élargir notre concept à la capitale de la gastronomie : Paris",
        picture: './media/project2.jpg',
    },
    {
        id: 3,
        name: 'Print It',
        languages: ['javascript', 'html', 'css', 'tous'],
        languagesIcons: ['fab fa-js', 'fa-brands fa-html5', 'fab fa-css3-alt'],
        source: 'https://github.com/Geoffrey-Schmit/Print-it',
        info: "L'objectif du  projet du site Print It était de le rendre dynamique en mettant en place un carrousel avec javascript. ",
        picture: './media/project3.jpg',
    },
    {
        id: 4,
        name: 'Sophie Bluel - Architecte',
        languages: ['javascript', 'html', 'css', 'tous'],
        languagesIcons: ['fab fa-js', 'fa-brands fa-html5', 'fab fa-css3-alt'],
        source: 'https://github.com/Geoffrey-Schmit/Portfolio-architecte-sophie-bluel-master',
        info: "Réalisation de la page de présentation des travaux de l'architecte, réalisation de la page de connexion de l'administrateur du site, réalisation de la modale permettant d'uploader de nouveaux médias",
        picture: './media/project4.jpg',
    },
    {
        id: 5,
        name: 'Webgencia',
        languages: ['react', 'sass', 'tous'],
        languagesIcons: ['fab fa-react', 'fab fa-sass'],
        source: '',
        info: "L'objectif de cette mission était de mener, préparer et planifier le projet de développement du site Menu Maker by Qwenta. Réalisation d'une veille technologique avec présentation de 15 slides, présenter les spécifications techniques et justifier nos choix, présentation de l'outil de gestion de projet et réalisation d'un tableau Kanban.",
        picture: './media/project5.jpg',
    },
    {
        id: 6,
        name: 'Kasa',
        languages: ['react', 'sass', 'tous'],
        languagesIcons: ['fab fa-react', 'fab fa-sass'],
        source: 'https://github.com/Geoffrey-Schmit/kasa',
        info: 'Refonte totale du site pour passer en JavaScript avec NodeJS côté back-end et React + Sass côté front-end.',
        picture: './media/project6.jpg',
    },
    {
        id: 7,
        name: 'Nina Carducci',
        languages: ['javascript', 'html', 'css', 'tous'],
        languagesIcons: ['fab fa-js', 'fa-brands fa-html5', 'fab fa-css3-alt'],
        source: 'https://github.com/Geoffrey-Schmit/ninacarducci.github.io-main',
        info: "L'objectif de cette mission était de faire une optimisation globale du site tant sur les performances que sur le SEO, mettre en place le référencement local en utilisant schema.org, ajout des métas pour les réseaux sociaux, modifications liées à l'accessibilité du site et produire un rapport d'optimisation complet.",
        picture: './media/project7.jpg',
    },
    {
        id: 8,
        name: '724 events',
        languages: ['react', 'sass', 'tous'],
        languagesIcons: ['fab fa-react', 'fab fa-sass'],
        source: 'https://github.com/Geoffrey-Schmit/Debuggez-une-application-React.JS-main',
        info: "L'objectif de la mission était de débugger le site de l'agence 724 event, débug du carrousel, débug du filtre pour la section nos réalisations, débug du formulaire d'envoie. Utilisation de React developer tools, réalisation du cahier de recette, réalisation de 3 tests unitaires et 3 tests d'intégration pour améliorer la couverture du code.",
        picture: './media/project8.jpg',
    },
    {
        id: 9,
        name: 'Argent Bank',
        languages: ['react', 'sass', 'tous'],
        languagesIcons: ['fab fa-react', 'fab fa-sass'],
        source: 'https://github.com/Geoffrey-Schmit/argentBank',
        info: "Création complète d'une application web bancaire responsive avec React et Sass, utilisation de Redux pour gérer le state de l'ensemble de l'application, système d'authentification + rédaction du fichier swagger pour fournir des modèles d'API concernant les transactions.",
        picture: './media/project9.jpg',
    },
];
