import React, { Component } from 'react';
import ProgressBar from './ProgressBar';

class Languages extends Component {
    state = {
        languages: [
            { id: 1, value: 'Javascript', xp: 0.9 },
            { id: 2, value: 'HTML', xp: 2 },
            { id: 3, value: 'Css', xp: 2 },
            { id: 4, value: 'Php', xp: 0.3 },
        ],
        frameworks: [
            { id: 1, value: 'React', xp: 0.9 },
            { id: 2, value: 'Sass', xp: 1.2 },
            { id: 3, value: 'Tailwind CSS', xp: 0.5 },
            { id: 4, value: 'Jest', xp: 0.6 },
        ],
    };
    render() {
        let { languages, frameworks } = this.state;
        return (
            <div className="languagesFrameworks">
                <ProgressBar languages={languages} className="languagesDisplay" title="languages" />
                <ProgressBar languages={frameworks} className="frameworksDisplay" title="frameworks & bibliothèques" />
            </div>
        );
    }
}

export default Languages;
