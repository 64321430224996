import React from 'react';

const Hobbies = () => {
    return (
        <div className="hobbies">
            <h3>Intérêts</h3>
            <ul>
                <li className="hobby">
                    <i className="fa-regular fa-futbol"></i>
                    <span>Football</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-fish"></i>
                    <span>Pêche</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-medal"></i>
                    <span>Boxe</span>
                </li>
                <li className="hobby">
                    <i className="fa-brands fa-bitcoin"></i>
                    <span>Crypto-monnaies</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-rocket"></i>
                    <span>Espace</span>
                </li>
            </ul>
        </div>
    );
};

export default Hobbies;
